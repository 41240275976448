import { useCallback, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { parseQuery, stringifyQuery } from '../../utils';

const useSearchQuery = <T extends object>(defaults = {} as T) => {
  const history = useHistory();
  const { pathname, search } = useLocation();
  const query = useMemo(() => ({ ...defaults, ...parseQuery(search) }) as T, [search]);
  const updateSearchQuery = useCallback(
    (
      query_params: object,
      { remove_keys = [], replace = false }: { remove_keys?: string[]; replace?: boolean } = {},
    ): void => {
      const new_query = replace
        ? query_params
        : {
            ...query,
            ...query_params,
          };
      remove_keys.forEach((key) => delete new_query[key]);
      const new_query_string = `?${stringifyQuery(new_query)}`;
      if (search !== new_query_string) {
        history.push(`${pathname}${new_query_string}`);
      }
    },
    [pathname, query, search, history],
  );

  return { query, updateSearchQuery };
};

export default useSearchQuery;

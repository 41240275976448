import { OrganizationRole } from '../typings/OrganizationMember.interface';
import { TeamRole } from '../typings/TeamMember.interface';

export const team_levels_by_role: Record<TeamRole, number> = {
  admin: 2,
  member: 1,
  viewer: 0,
};

export const organization_levels_by_role: Record<OrganizationRole, number> = {
  owner: 3,
  admin: 2,
  member: 1,
  viewer: 0,
};

import LogRocket from 'logrocket';
import { createContext, PropsWithChildren, useContext, useEffect, useMemo } from 'react';
import useSWR from 'swr';

import { User } from '../../../../../typings/User.interface';
import APIMethodKeys from '../../client/APIMethodKeys';
import { HookdeckAPIError } from '../../client/Hookdeck';
import Icon from '../common/base/Icon';
import FullscreenLayout from '../layouts/FullscreenLayout';
import { GlobalContext } from './GlobalContext';
import Analytics from '../../client/Analytics';

export type UserContext = {
  user: User | null;
  mutateUser: ((user: User) => void) | null;
  user_error: HookdeckAPIError | null | undefined;
  loading_user: boolean;
};

export const UserContext = createContext<UserContext>({
  mutateUser: null,
  user: null,
  user_error: null,
  loading_user: true,
});

export const UserContextProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const { HookdeckAPI, configs } = useContext(GlobalContext);
  const {
    data: user,
    error: user_error,
    mutate,
  } = useSWR(APIMethodKeys.session.me(), () => HookdeckAPI.session.me(), {
    shouldRetryOnError: false,
  });

  useEffect(() => {
    if (user) {
      const userIdentifier = {
        name: user.name,
        email: user.email,
        createdAt: user.created_at,
        env: configs.ENV,
      };
      if (configs.ENV === 'production' && LogRocket) {
        LogRocket.init('hafoob/hookdeck', {
          rootHostname: configs.ROOT_DOMAIN,
        });
        LogRocket.identify(user.id, userIdentifier);
      }

      Analytics.identify(user.id, userIdentifier);

      LogRocket.getSessionURL((sessionURL) => {
        Analytics.capture('Started Logrocket Session', {
          sessionURL: sessionURL,
        });
        HookdeckAPI.track.event('Started Session', {
          logrocket_session_url: sessionURL,
        });
      });

      if ((window as any).$crisp) {
        (window as any).$crisp.push(['set', 'user:email', [user.email]]);
        (window as any).$crisp.push(['set', 'user:nickname', [user.name]]);
      }
    }
  }, [user]);

  const loading_user = user === undefined && user_error === undefined;

  const context: UserContext = useMemo(
    () => ({
      user: user || null,
      mutateUser: mutate,
      user_error,
      loading_user,
    }),
    [user, user_error, mutate, loading_user],
  );

  if (loading_user) {
    return (
      <FullscreenLayout center>
        <Icon icon="loading" muted />
      </FullscreenLayout>
    );
  }

  return <UserContext.Provider value={context}>{children}</UserContext.Provider>;
};

export const withUserContext = (prop_keys: string[]) =>
  function <C extends React.ComponentClass>(Component: C): C {
    const getProps = (context) =>
      Object.keys(context)
        .filter((key) => prop_keys.includes(key))
        .reduce((filtered_props, key) => ({ ...filtered_props, [key]: context[key] }), {});
    const UserContextConsumer = (props) => {
      return (
        <UserContext.Consumer>
          {(context) => <Component {...{ ...getProps(context), ...props }} />}
        </UserContext.Consumer>
      );
    };
    return UserContextConsumer as any as C;
  };

import { colors } from './colors';

const color_tokens = {
  surface: {
    base: {
      background: colors.dark_levels[0],
      surface: colors.dark_levels[1],
      variant_surface: colors.dark_levels[2],
      variant_surface_2: colors.dark_levels[2],
      primary: colors.blue[600],
      success: colors.green[600],
      warning: colors.yellow[600],
      danger: colors.red[600],
      disabled: colors.dark_levels[1],
      backdrop: `rgba(20, 20, 18, 0.6)`,
      // States
      hover: {
        neutral: colors.dark_levels[2],
        neutral_variant: colors.dark_levels[3],
        primary: colors.blue[400],
        danger: colors.red[400],
      },
      active: {
        neutral: colors.dark_levels[1],
        neutral_variant: colors.dark_levels[2],
        primary: colors.blue[500],
        danger: colors.red[500],
      },
    },
    chart: {
      primary: colors.blue[400],
      danger: colors.red[400],
      success: colors.green[400],
    },
    container: {
      neutral: colors.dark_levels[3],
      primary: colors.blue[800],
      success: colors.green[800],
      warning: colors.yellow[900],
      danger: colors.red[800],
      highlight: colors.yellow[800],
      // States
      hover: {
        neutral: colors.dark_levels[3],
        primary: colors.blue[700],
        success: colors.green[600],
        warning: colors.yellow[800],
        danger: colors.red[700],
      },
      active: {
        neutral: colors.dark_levels[2],
        primary: colors.blue[800],
        success: colors.green[800],
        warning: colors.yellow[900],
        danger: colors.red[800],
      },
    },
  },
  on: {
    neutral: {
      primary_neutral: colors.neutral[100],
      secondary_neutral: colors.neutral[600],
      primary: colors.blue[300],
      danger: colors.red[300],
      warning: colors.yellow[300],
      success: colors.green[300],
      disabled: colors.neutral[800],
      loading: colors.neutral[700],
    },
    code: {
      boolean: colors.blue[300],
      string: colors.green[300],
      number: colors.yellow[300],
      null: colors.red[300],
    },
    hue: {
      primary: colors.neutral[100],
      success: colors.neutral[100],
      warning: colors.yellow[900],
      danger: colors.neutral[100],
    },
    hue_container: {
      primary: colors.blue[200],
      primary_variant: colors.blue[400],
      secondary: colors.blue[400],
      success: colors.green[200],
      warning: colors.yellow[300],
      danger: colors.red[200],
    },
  },
  outline: {
    neutral: colors.dark_levels[4],
    primary: colors.blue[600],
    success: colors.green[600],
    warning: colors.yellow[800],
    danger: colors.red[700],
    // States
    hover: {
      neutral: colors.neutral[800],
      neutral_variant: colors.neutral[800],
      container_primary: colors.blue[500],
    },
    focus: {
      primary: colors.blue[300],
    },
    active: {
      neutral: colors.neutral[800],
      container_primary: colors.blue[600],
    },
  },
};

const elevation = {
  '1': '0px 1px 0px 0px rgba(0, 0, 0, 0.25)',
  '2': '0px 1px 0px 0px rgba(0, 0, 0, 0.4), 0px -1px 0px 0px rgba(0, 0, 0, 0.20) inset',
  '3': '0px 8px 24px 0px rgba(0, 0, 0, 0.25)',
};

export default {
  colors: color_tokens,
  elevation,
};

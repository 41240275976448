/**
 * Beginning to centralize analytics across PostHog and Segement.
 */

declare global {
  interface Window {
    posthog: any;
  }
}

const clientCheck = () => {
  if (typeof window === 'undefined') {
    console.warn('Analytics cannot be called on server side');
    return false;
  }
  return true;
};

const sdkCheck = () => {
  if (!window.posthog) {
    console.warn('Analytics cannot be called without a posthog instance');
    return false;
  }
  return true;
};

const capture = (eventName: string, data: any) => {
  if (!clientCheck()) return;
  if (!sdkCheck()) return;

  window.posthog.capture(eventName, data);
};

const identify = (userId: string, userIdentifier: any) => {
  if (!clientCheck()) return;
  if (!sdkCheck()) return;

  window.posthog.identify(userId, userIdentifier);
};

export default { capture, identify };

import { colors } from './colors';

const color_tokens = {
  surface: {
    base: {
      background: colors.stone[50],
      surface: colors.white,
      variant_surface: colors.neutral[200],
      variant_surface_2: colors.neutral[100],
      primary: colors.blue[500],
      success: colors.green[500],
      warning: colors.yellow[500],
      danger: colors.red[500],
      disabled: colors.neutral[100],
      backdrop: `rgba(250, 250, 248, 0.6)`,
      // States
      hover: {
        neutral: colors.neutral[100],
        neutral_variant: colors.neutral[200],
        primary: colors.blue[600],
        danger: colors.red[600],
      },
      active: {
        neutral: colors.neutral[200],
        neutral_variant: colors.neutral[400],
        primary: colors.blue[700],
        danger: colors.red[700],
      },
    },
    chart: {
      primary: colors.blue[700],
      danger: colors.red[400],
      success: colors.green[700],
    },
    container: {
      neutral: colors.neutral[200],
      primary: colors.blue[50],
      success: colors.green[50],
      warning: colors.yellow[100],
      danger: colors.red[50],
      highlight: colors.yellow[200],
      // States
      hover: {
        neutral: colors.neutral[300],
        primary: colors.blue[100],
        success: colors.green[100],
        warning: colors.yellow[300],
        danger: colors.red[100],
      },
      active: {
        neutral: colors.neutral[400],
        primary: colors.blue[200],
        success: colors.green[200],
        warning: colors.yellow[400],
        danger: colors.red[200],
      },
    },
  },
  on: {
    neutral: {
      primary_neutral: colors.black,
      secondary_neutral: colors.neutral[700],
      primary: colors.blue[500],
      danger: colors.red[500],
      warning: colors.yellow[500],
      success: colors.green[500],
      disabled: colors.neutral[600],
      loading: colors.neutral[700],
    },
    code: {
      boolean: colors.blue[600],
      string: colors.green[600],
      number: colors.yellow[700],
      null: colors.red[600],
    },
    hue: {
      primary: colors.white,
      success: colors.white,
      warning: colors.yellow[900],
      danger: colors.white,
    },
    hue_container: {
      primary: colors.blue[500],
      primary_variant: colors.blue[300],
      secondary: colors.blue[300],
      success: colors.green[600],
      warning: colors.yellow[900],
      danger: colors.red[500],
    },
  },
  outline: {
    neutral: colors.neutral[200],
    primary: colors.blue[100],
    success: colors.green[100],
    warning: colors.yellow[300],
    danger: colors.red[100],
    // States
    hover: {
      neutral: colors.neutral[300],
      neutral_variant: colors.neutral[500],
      container_primary: colors.blue[200],
    },
    focus: {
      primary: colors.blue[300],
    },
    active: {
      neutral: colors.neutral[400],
      container_primary: colors.blue[300],
    },
  },
};

const elevation = {
  '1': '0px 1px 0px 0px rgba(0, 0, 0, 0.02)',
  '2': '0px 1px 0px 0px rgba(0, 0, 0, 0.08), 0px -1px 0px 0px rgba(0, 0, 0, 0.20) inset',
  '3': '0px 8px 24px 0px rgba(82, 80, 74, 0.12)',
};

export default {
  colors: color_tokens,
  elevation,
};
